@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.success-icon {
    height: 80px;
    width: 60px;
}

.success-icon-circle {
    animation: enter-0-360 1s forwards ease-in-out;
}

.success-icon-checkmark {
    animation: fade-in 0.5s forwards linear;
}