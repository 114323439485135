.popup {
	border-radius: 12px;
	position: relative;
	max-height: 80vh;
	max-width: 80vw;
	width: auto;
	box-shadow: 0px 0px 8px rgba(0,0,0,0.3);
	overflow: hidden;
}

.popup-image {
	height: 100%;
	max-height: 80vh;
	width: 100%;
	border-radius: 12px;
}

.popup-close-button {
	position: absolute !important;
	top: 1%;
	right: 1%;
}

.popup-close-icon {
	padding: 4px;
	background-color: lightgray;
	border-radius: 50%;
}