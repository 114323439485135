@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.App {
  text-align: center;
  min-height: 100vh;
  max-width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader-background-glow {
  0% {
    background-color: gainsboro;
  }

  50% {
    background-color: gainsboro;
  }

  75% {
    background-color: #acacac;
  }

  100% {
    background-color: gainsboro;
  }
}

@keyframes logo-opacity-animation {
  0% {
    transform: scale(0.4);
  }

  50% {
    transform: scale(0.45);
  }

  100% {
    transform: scale(0.4);
  }
}

#loader-title {
  font-family: 'Poppins', sans-serif;
  animation: loader-title-glow infinite 2s ease;
}

#loader-background {
  background-color: #bdc3c9;
}

#bookea-logo {
  fill: white;
  transform: scale(0.4);
  animation: logo-opacity-animation infinite 1.5s ease;
}

#loader-caption {
  color: white;
  font-family: 'Poppins', sans-serif;
}

::-webkit-scrollbar {
  display: none;
}